@import "../../colors.css";

.icon {
	color: var(--primaryColor);
	margin: 0 16px 0 16px;
	height: 2rem;
	width: 40px !important;
}

.Kategorie {
	display: inline-flex;
	align-items: center;
	margin: 20px 0 20px 0;
}

.Kategorie h3 {
	text-align: left;
	font-size: 1.5rem;
	line-height: 2rem;
}

.wrapper h2 {
	font-size: 2rem;
}

@media (max-width: 380px) {
	.Kategorie h3 {
		font-size: 1.2rem;
		line-height: 1.5rem;
	}
}

.coloredHeading {
	color: var(--primaryColor);
}

@media (min-width: 980px) {
	.Kategorie h3 {
		font-size: 1.2rem;
		line-height: 1.5rem;
	}
}

@media (min-width: 1170px) {
	.Kategorie h3 {
		font-size: 1.5rem;
		line-height: 2rem;
	}
}
