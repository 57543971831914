@import "../../colors.css";

.outerWrapper {
	position: fixed;
	height: 100vh;
	width: 100vw;
	top: 0;
	left: 0;
	z-index: 5566;
	display: flex;
	justify-content: center;
	align-items: center;
}

.wrapper {
	max-width: 600px;
	max-height: 400px;
	padding: 50px 75px;
	background-color: var(--modalBackground);
	border-radius: 25px;
	display: flex;
	flex-direction: column;
	z-index: 666;
}

.outerWrapper p {
	color: var(--textColor);
	font-size: 1rem;
}

.outerWrapper h5 {
	font-size: 2rem;
	color: var(--headingColor);
}

.outerWrapper a {
	text-decoration: none;
	position: relative;
}
.outerWrapper a:hover {
	text-decoration: underline;
}

.outerBackground {
	position: fixed;
	height: 100vh;
	width: 100vw;
	top: 0;
	left: 0;
	opacity: 0.7;
	background-color: var(--overlayColor);
	z-index: 67;
}

.outerBackground:hover {
	cursor: pointer;
}

.closeModal {
	font-size: 2rem;
	width: 2rem;
	height: 2rem;
	position: absolute;
	right: -75px;
	top: -50px;
	color: var(--textColor);
	line-height: 1.5rem;
	padding: 10px;
	border-radius: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.closeModal:hover {
	cursor: pointer;
}

.closeModalWrapper {
	position: sticky;
	top: -50px;
	right: -75px;
	display: flex;
	align-items: center;
	z-index: 1000;
}

.button {
	font-size: 24px;
	height: 80px;
	width: 300;
	font-weight: 700;
	background-color: var(--secondaryColor);
	border: none;
	border-radius: 10px;
	color: white;
	cursor: pointer;
	padding: 0 30px;
}

.button:hover {
	color: white;
	text-decoration: underline;
}
