@import "./colors.css";

.App {
	text-align: center;
	min-height: 100vh;
	position: relative;
	display: grid;
	grid-template-rows: auto 1fr auto;
}

body,
html {
	height: 100%;
	position: relative;
	background-color: var(--backgroundColor);
	/* No scrollbar for Windows */
	/* Firefox solution */
	scrollbar-width: none;
	/* Internet Explorer solution */
	-ms-overflow-style: none;
}

/* Chrome and Safari */
html::-webkit-scrollbar {
	display: none;
}

* {
	/* user-select: none; */
	margin: 0px;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

html {
	scroll-behavior: smooth;
}

p {
	font-size: 1.125rem;
	line-height: 1.5rem;
}

h1 {
	font-size: 2rem;
	margin-top: 40px;
	margin-bottom: 40px;
}
h2 {
	font-size: 2rem;
}

p {
	color: var(--textColor);
}

.primaryColor {
	color: #18a032;
}
