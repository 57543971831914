.wrapper {
	text-align: left;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}

.contentWrapper {
	padding-top: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.textSection {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.heading {
	display: flex;
	justify-content: center;
	gap: 16px;
	flex-wrap: wrap;
}

.textSection p {
	max-width: 55ch;
}

.image {
	border-radius: 8px;
	width: 90%;
}

@media (min-width: 800px) {
	.image {
		width: 60%;
	}

	.textSection p {
		font-size: 1.25rem;
	}
}

.imageWrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 100px;
}
