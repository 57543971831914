@import "../../colors.css";

.Banner1 h1 {
	margin: 0px;
	vertical-align: center;
	top: 0px;
	width: 100% !important;
	color: var(--headingColor);
	font-weight: 400;
	text-transform: uppercase;
	word-spacing: 8px;
}

@media (max-width: 370px) {
	.ImageBanner {
		height: calc(100vh - 90px);
	}
}

.Banner1 {
	height: 154px;
	background-color: var(--bannerColor);
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	margin: 0 0 50px 0;
}

.Banner2 h2 {
	line-height: 36px;
	vertical-align: center;
	top: 0px;
	width: 100vw;
	color: var(--headingColor);
	font-weight: 400;
	text-transform: uppercase;
}

.Banner2 {
	height: 36px;
	padding: 34px 0 34px 0;
	background-color: var(--bannerColor);
	position: relative;
	margin: 0 0 50px 0;
}

.ImageBanner {
	background-color: var(--bannerColor);
	display: flex;
	height: calc(100vw / 3.36167814 + 4rem);
	align-content: center;
	position: relative;
	margin: 0 0 50px 0;
}

.ImageBanner h1 {
	position: absolute;
	margin: auto;
	width: 100%;
	bottom: 0;
	z-index: 555;
	color: var(--primaryColor);
	font-size: 3rem;
	line-height: 4rem;
}

@media (max-width: 1000px) {
	.ImageBanner h1 {
		font-size: 2.5rem;
	}
}

@media (max-width: 830px) {
	.ImageBanner h1 {
		font-size: 2rem;
	}

	.smallHeading {
		height: calc(100vw / 3.36167814 + 10rem);
	}

	.smallHeading h1 {
		bottom: 25px;
	}
}

@media (min-width: 831px) {
	.ImageBanner h1 {
		margin-left: 60px;
		position: initial;
		width: 100vw;
	}
	.ImageBanner .image svg {
		height: 100%;
		width: auto;
		position: absolute;
	}

	.ImageBanner .image {
		width: 60%;
		max-width: 360px;
	}

	.ImageBanner {
		max-height: 400px;
	}
}

@media (max-width: 658px) {
	.ImageBanner {
		height: calc(100vw / 3.36167814 + 8rem);
	}

	.smallHeading {
		height: calc(100vw / 3.36167814 + 9rem);
	}

	.smallHeading h1 {
		bottom: 15px;
	}
}

@media (max-width: 310px) {
	.smallHeading {
		height: calc(100vw / 3.36167814 + 7rem);
	}

	.smallHeading h1 {
		bottom: 15px;
	}
}

@media (max-width: 420px) {
	.ImageBanner h1 {
		line-height: 2.5rem;
		font-size: 1.6rem;
		bottom: 20px;
	}
}

.image {
	width: 100%;
}

.image svg {
	position: absolute;
	top: 0;
	right: 0;
	height: auto;
	width: 100%;
}

.image svg path {
	width: 100%;
}
