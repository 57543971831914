.menu {
	display: flex;
	flex-direction: column !important;
	height: auto;
	margin-top: 60px;
	align-items: baseline !important;
	justify-content: space-evenly !important;

	a {
		padding: 1.5rem 2rem;
		font-size: 1rem;
		font-weight: 700;
		text-align: left;
		transition: 0.5s ease-in-out;
		text-decoration: none;
		flex-grow: 1;

		&:last-of-type {
			padding-bottom: 3rem;
		}

		&:hover {
			color: #18a032;
		}
	}
	.active {
		color: var(--primaryColor) !important;
	}
}
