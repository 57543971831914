html,
body {
	height: 100%;
	width: 100%;
	margin: 0;
	padding: 0;
	left: 0;
	top: 0;
	font-size: 100%;
	font-family: "Open Sans";
}

/* POSITIONING */

.left {
	text-align: left;
}

.right {
	text-align: right;
}

.center {
	text-align: center;
	margin-left: auto;
	margin-right: auto;
}

.justify {
	text-align: justify;
}

.m-0 {
	margin: 0px !important;
}

.mb-25 {
	margin-bottom: 25px !important;
}

.mt-25 {
	margin-top: 25px !important;
}

.mb-50 {
	margin-bottom: 50px !important;
}

.mb-75 {
	margin-bottom: 75px !important;
}

.mt-75 {
	margin-top: 75px !important;
}

.mb-100 {
	margin-bottom: 100px !important;
}

.mt-100 {
	margin-top: 100px !important;
}

.mt-200 {
	margin-top: 200px !important;
}

.mt-50 {
	margin-top: 50px !important;
}

.pb-10 {
	padding-bottom: 10px;
}

.p-10 {
	padding: 10px;
}

.ml-5 {
	margin-left: 5px;
}

.noFloat {
	float: none !important;
}

.inlineBlock {
	display: inline-block;
}

.rightFloat {
	float: right !important;
}

/* ==== GRID SYSTEM ==== */

.container {
	width: 94%;
	margin-left: auto;
	margin-right: auto;
}

.row {
	position: relative;
	width: 100%;
}

.row [class^="col"] {
	float: left;
	margin: 0.5rem 2%;
	min-height: 0.125rem;
}

.row [class^="colB"] {
	float: left;
	margin: 0.5rem 2%;
	min-height: 0.125rem;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
	width: 96%;
}

.colB-1,
.colB-2,
.colB-3,
.colB-4,
.colB-5,
.colB-6,
.colB-7,
.colB-8,
.colB-9,
.colB-10,
.colB-11,
.colB-12 {
	width: 96%;
}

.col-1-sm {
	width: 4.33%;
}

.col-2-sm {
	width: 12.66%;
}

.col-3-sm {
	width: 21%;
}

.col-4-sm {
	width: 29.33%;
}

.col-5-sm {
	width: 37.66%;
}

.col-6-sm {
	width: 46%;
}

.col-7-sm {
	width: 54.33%;
}

.col-8-sm {
	width: 62.66%;
}

.col-9-sm {
	width: 71%;
}

.col-10-sm {
	width: 79.33%;
}

.col-11-sm {
	width: 87.66%;
}

.col-12-sm {
	width: 96%;
}

.row::after {
	content: "";
	display: table;
	clear: both;
}

.hidden-sm {
	display: none;
}

@media only screen and (min-width: 33.75em) {
	/* 540px */
	.container {
		width: 80%;
	}
}

@media only screen and (min-width: 62em) {
	/* 720px */
	.colB-1 {
		width: 4.33%;
	}

	.colB-2 {
		width: 12.66%;
	}

	.colB-3 {
		width: 21%;
	}

	.colB-4 {
		width: 29.33%;
	}

	.colB-5 {
		width: 37.66%;
	}

	.colB-6 {
		width: 46%;
	}

	.colB-7 {
		width: 54.33%;
	}

	.colB-8 {
		width: 62.66%;
	}

	.colB-9 {
		width: 71%;
	}

	.colB-10 {
		width: 79.33%;
	}

	.colB-11 {
		width: 87.66%;
	}

	.colB-12 {
		width: 96%;
	}

	.hidden-sm {
		display: block;
	}
}
@media only screen and (min-width: 45em) {
	/* 720px */
	.col-1 {
		width: 4.33%;
	}

	.col-2 {
		width: 12.66%;
	}

	.col-3 {
		width: 21%;
	}

	.col-4 {
		width: 29.33%;
	}

	.col-5 {
		width: 37.66%;
	}

	.col-6 {
		width: 46%;
	}

	.col-7 {
		width: 54.33%;
	}

	.col-8 {
		width: 62.66%;
	}

	.col-9 {
		width: 71%;
	}

	.col-10 {
		width: 79.33%;
	}

	.col-11 {
		width: 87.66%;
	}

	.col-12 {
		width: 96%;
	}

	.hidden-sm {
		display: block;
	}
}

@media only screen and (min-width: 71.25em) {
	/* 960px */
	.container {
		width: 80%;
		max-width: 71.25rem;
	}

	.fullWidthContainer {
		max-width: 100vw !important;
	}
}
