.rec1,
.rec2,
.rec3 {
	position: absolute;
	top: 0px;
	width: 40px;
	height: 6px;
	background-color: #18a032;
	transition-duration: 1s;
	transition-timing-function: ease-in-out;
}

.rec2 {
	transition-duration: 0.6s;
	top: 12px;
}

.rec3 {
	top: 24px;
}

.rec1,
.rec2 {
	margin-bottom: 10px;
}

.clicked {
	margin-bottom: 0px;
}

.clicked.rec1 {
	/* background-color: red; */
	transform: translateX(12px) rotate(45deg) translateY(17px);
	background-color: white;
}

.clicked.rec2 {
	transform: scale(0);
}

.clicked.rec3 {
	/* background-color: purple; */
	transform: translateX(12px) rotate(-45deg) translateY(-17px);
	background-color: white;
}

.hamburger {
	position: relative;
	height: 30px;
	width: 40px;
	cursor: pointer;
	// top: 100px;
	// left: 400px;
}
