@import "../../colors.css";

.container img {
	margin: 0px !important;
	max-height: 400px;
	border-radius: 10px;
	object-fit: contain;
}

.text p {
	font-size: 16px;
	margin: 0px auto 16px 30px;
	text-align: left;
}

@media (max-width: 720px) {
	.text p {
		margin-left: 0px;
		margin-right: 0px;
	}
	.contactBtn {
		display: flex;
		justify-content: center;
		margin-top: 75px !important;
	}
	.contactBtn button {
		margin-left: 0px !important;
	}
	.container img {
		display: none;
	}
}

.contactBtn {
	text-align: left;
}

.contactBtn button {
	margin-left: 30px;
}

.space {
	margin: 0px !important;
}
