@import "../../colors.css";

.btnWrapper button {
	height: 40px;
	background-color: var(--textColor);
	color: white;
	border: none;
	cursor: pointer;
	transform: translateY(2px);
}

.btnWrapper button:hover {
	color: var(--primaryColor);
}

.downloadBtn {
	width: 100% !important;
	margin: 0 !important;
	display: block;
	background-color: var(--textColor) !important;
}

.btnWrapper {
	position: sticky;
	bottom: 0px;
}

.btnWrapper a {
	text-decoration: none;
	color: white;
}

.btnWrapper:hover a {
	color: var(--primaryColor);
}
