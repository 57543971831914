@import "../colors.css";

.upperNavbar {
	width: 100%;
	height: 39px;
	background-color: var(--primaryColor);
}

.border2 {
	width: 100vw;
	height: 6px;
	background-color: var(--tertiaryColor);
}
.border1 {
	width: 100vw;
	height: 6px;
	background-color: var(--secondaryColor);
}

.lowerProjectsNavbar {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	width: 100%;
	margin: 0 auto 0 auto;
	position: sticky !important;
	top: 0px;
	background-color: var(--backgroundColor);
	z-index: 666;
	height: 90px;

	@media (max-width: 760px) {
		height: 124px;
	}

	a {
		text-decoration: none;
		font-weight: 700;
		color: var(--textColor);
		font-size: 1rem;
		font-family: "Open Sans", Arial, sans-serif;
		font-style: normal;
		padding: 0 15px;
		padding-bottom: 12px;
		display: inline-block;
		margin: 0;

		@media (min-width: 800px) {
			font-size: 1.25rem;
		}
	}

	.menubar {
		padding-right: 1rem;
	}

	.lowerProjectsNavbarContent {
		width: 100%;
		max-width: 1140px;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		flex-direction: column;
		margin: 0 auto 0 auto;

		@media (min-width: 760px) {
			flex-direction: row;
			justify-content: center;
			align-items: flex-end;
		}

		@media (min-width: 521px) {
			justify-content: space-between;
		}
	}
}

.lowerMainNavbar {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	width: 100%;

	margin: 0 auto 0 auto;
	position: sticky !important;
	top: 0px;
	background-color: var(--backgroundColor);
	height: 90px;
	z-index: 666;

	a {
		text-decoration: none;
		font-weight: 700;
		color: var(--textColor);
		font-size: 1rem;
		font-family: "Open Sans", Arial, sans-serif;
		font-style: normal;
		padding: 0 15px;
		padding-bottom: 12px;
		display: inline-block;
		margin: 0;
	}

	nav {
		padding-right: 90px;
		display: flex;
		align-content: flex-end;
		align-items: flex-end;
		justify-items: flex-end;
		justify-content: flex-end;
	}

	.lowerMainNavbarContent {
		width: 100%;
		max-width: 1140px;
		display: flex;
		justify-content: center;
		align-items: flex-end;
		margin: 0 auto 0 auto;

		@media (min-width: 521px) {
			justify-content: space-between;
		}
	}

	@media (max-width: 520px) {
		.logo svg {
			display: none;
		}
	}

	.menubar {
		display: flex;
		height: 90px;
		align-items: center;
	}
}

.mainNavbarBorder {
	width: 100%;
	height: 2px;
	background-color: #eee;
	position: sticky;
	top: 90px;
	z-index: 3;
}

.projectsNavbarBorder {
	width: 100%;
	height: 2px;
	background-color: #eee;
	position: sticky;
	top: 90px;
	z-index: 3;

	@media (max-width: 760px) {
		top: 124px;
	}
}

.logo {
	height: 90px;
	display: flex;
	align-items: center;
	cursor: pointer;
}
.logo svg {
	height: 80px !important;
	padding-left: 60px;
}
.logo a {
	padding-bottom: 0;
}

.hamburger {
	@media (min-width: 521px) {
		margin-right: 2rem;
	}
}

@media (max-width: 1060px) {
	.logo svg {
		padding-left: 10px;
	}

	.lowerMainNavbar nav {
		padding: 0px;
	}
}

@media (max-width: 410px) {
	.menubarItems a {
		font-size: 0.9375rem;
	}
}

@media (max-width: 388px) {
	.menubarItems a {
		font-size: 1rem;
	}
}

@media (min-width: 919px) {
	.Friesoythe.mainMenu {
		margin-right: 70px;
	}
}

@media (min-width: 1061px) {
	.Friesoythe.mainMenu {
		margin-right: 0px;
	}
}

.children {
	background-color: white;
	top: 90px;
	position: absolute;
	width: 100vw;
	height: 100vh;
	margin-top: 0px !important;
}

.flexMenu a {
	display: block !important;
	color: var(--textColor) !important;
	font-size: 1.5rem;
	padding: 0;
	margin-bottom: 30px;
}

.flexMenu {
	display: flex;
	flex-direction: column;
	justify-content: flex-start !important;
	align-items: center !important;
	height: calc(100vh - 90px);
	height: 100%;
}

.active {
	color: var(--primaryColor) !important;
}

.NavbarContact {
	display: flex;
	height: 39px;
	align-content: center;
	align-items: center;
	justify-content: flex-end;
	justify-items: flex-end;
	margin-right: 20px;
}

.NavbarContact a,
.NavbarContact p {
	display: inline;
	color: white;
	text-decoration: none;
}

.NavbarContact .icon {
	margin: 0 8px 0 8px;
	color: white;
}

.NavbarContact a:hover {
	text-decoration: underline;
}

@media (max-width: 480px) {
	.NavbarContact {
		font-size: 12px !important;
	}
}

@media (max-width: 330px) {
	.NavbarContact {
		font-size: 11px !important;
	}
}

@media (max-width: 300px) {
	.NavbarContact {
		font-size: 10px !important;
	}
}

.NavbarContact a,
.NavbarContact p {
	font-size: inherit;
}
