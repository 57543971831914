.outerWrapper {
	position: relative;
	bottom: 0;
	width: 100%;
}

.container {
	background-color: #0e0e0e;
	color: white;
	bottom: 0;
	padding-bottom: 100px;
	padding-top: 60px;
}

.container h5 {
	font-weight: 700;
	font-size: 1.1812rem;
	line-height: 18px;
	margin-bottom: 14px;
}

.container p {
	font-size: 13.86px;
	line-height: 24px;
	color: #777777;
	font-weight: 600;
	margin: 0;
}

.container p a {
	color: #18a032;
	text-decoration: none;
}

.container .rechtliches a,
.container .seiten a {
	display: block;
}

.container .rechtliches a,
.container .seiten a {
	text-decoration: none;
	color: white;
	font-size: 12.6px;
	line-height: 24px;
}

.container .rechtliches a::before,
.container .seiten div::before,
.container .seiten .specificMenu a::before {
	color: #777777;
	content: ">";
	margin-right: 10px;
}

.container .seiten div a {
	position: absolute;
	display: initial !important;
}

.container .seiten div {
	position: relative;
	width: 100%;
}

.copyright {
	height: 132px;
	background-color: #000;
}

.copyright .copyrightBox p {
	color: #555555;
	font-size: 12.6px;
	line-height: 24px;
	height: 24px;
}

.copyrightBox {
	display: flex;
	height: 100%;
	align-items: center;
}
