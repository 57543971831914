@import "../../colors.css";

.container img {
	margin: 0px !important;
	max-height: 400px;
	border-radius: 10px;
	object-fit: contain;
}

.text p {
	font-size: 16px;
	margin: 0px auto 0px 30px;
	text-align: left;
}

@media (max-width: 720px) {
	.text p {
		margin-left: 0px;
		margin-right: 0px;
	}
	.contactBtn {
		display: flex;
		justify-content: center;
		margin-top: 75px !important;
	}
	.contactBtn button {
		margin-left: 0px !important;
	}
	.container img {
		display: none;
	}
}

.mainWrapper button {
	text-decoration: none;
	border: none;
	font-size: 16px;
	background-color: transparent;
	font-family: "Open Sans";
	font-weight: 600;
	color: var(--textColor);
	fill: var(--textColor);
	position: relative;
	bottom: 100px;
	margin: 0px auto 0px auto;
	margin-right: 10px;
}

@media (max-width: 830px) {
	.mainWrapper button {
		bottom: 75px;
	}
}

.mainWrapper button:hover {
	text-decoration: solid;
	color: var(--primaryColor);
	fill: var(--primaryColor);
	cursor: pointer;
}

.arrow {
	height: 10px;
	margin-right: 10px;
}

.arrow g {
	fill: inherit;
}

.contactBtn {
	text-align: left;
}

.contactBtn button {
	margin-left: 30px;
}

.space {
	margin: 0px !important;
}

.downloadProtocolBtn {
	text-align: left;
}

.downloadProtocolBtn button {
	margin-left: 30px;
}
