:root {
	--primaryColor: #18a032;
	--secondaryColor: #00a1cf;
	--tertiaryColor: #d1d82a;
	--headingColor: #212529;
	--bannerColor: #f7f7f7;
	--textColor: #444444;
	--overlayColor: #444444;
	--cardColor: #ffffff;
	--modalBackground: #fefefe;
	--backgroundColor: #ffffff;
}
