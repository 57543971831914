.closer {
	background-color: #2c2c2c !important;
}
.wrapper {
	height: 60px;
	width: 300px;
	cursor: pointer;
	border-radius: 30px;
	background-color: #f0f0f0;
	display: flex;
	gap: 2rem;
	padding: 0px;
	justify-content: center;
	align-items: center;
	transition: 0.75s ease-in-out;
	position: relative;
	z-index: 3;
	user-select: none;

	@media (min-width: 521px) {
		width: 120px;
		gap: 0;
	}

	@media (min-width: 680px) {
		width: 300px;
		justify-content: space-evenly;
		gap: 2rem;
	}

	@media (min-width: 780px) {
		width: 400px;
	}

	.toggleMenuText {
		display: inline;
		font-size: 1.25rem;
		font-weight: 700;
		color: #18a032;
		transition: 1s ease-in-out;
		padding-left: 2rem;
		user-select: none;
	}

	.alternateText {
		color: #fff;
	}

	.hamburger {
		@media (min-width: 680px) {
			padding-right: 2rem;
		}
	}
}

.contentContainer {
	height: 0px;
	width: 300px;
	background-color: #f0f0f0;
	border-radius: 0px 0px 30px 30px;
	position: absolute;
	top: 40px;
	transition: 0.75s ease-in-out;
	z-index: 2;
	overflow: hidden;
	display: flex;
	user-select: none;

	@media (min-width: 521px) {
		opacity: 0;
		top: 15px;
		border-radius: 30px;
		right: 32px;
		transition: opacity 0.75s;
	}

	@media (min-width: 680px) {
		top: 40px;
		border-radius: 0 0 30px 30px;
		height: 0;
		opacity: 1;
		transition: height 0.75s;
	}

	@media (min-width: 780px) {
		width: 400px;
	}

	@media (min-width: 1140px) {
		right: auto;
	}
}

.toggledContentContainer {
	height: auto;
	@media (min-width: 520px) {
		opacity: 1;
	}
}

.backdrop {
	transition: 0.5s opacity linear;
	background-color: #000;
	opacity: 0;
	height: 0px;
	width: 100vw;
	z-index: -100;
	position: absolute;
	top: 0;
	left: 0;
}

.backdropOpen {
	opacity: 0.2 !important;
	z-index: 1 !important;
	height: 100vh;
}
