@import "../../colors.css";

.wrapper {
	background-color: var(--primaryColor);
	border-radius: 25px;
	overflow: hidden;
	position: relative;
	max-width: 340px;
}

.overlay {
	border-radius: 25px;
	background-color: var(--overlayColor);
	color: white;
	height: 100%;
	width: 100%;
	position: absolute;
	transition: 0.5s ease;
	opacity: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.overlay:hover {
	opacity: 1;
}
.overlay p,
.overlay h2 {
	color: inherit;
}
.overlay h2 {
	height: 10%;
}

.textSection p,
.textSection h2 {
	text-align: left;
	margin: 0 1rem 0 1rem;
	color: var(--headingColor);
}

.textSection p {
	font-size: 1.125rem;
}

.textSection h2 {
	font-size: 2rem;
}

.container {
	display: flex;
	justify-content: center;
	position: relative;
	width: 340px;
}

.contact {
	padding: 10px 20px;
	background-color: rgb(46, 46, 46);
	border: 2px solid rgb(22, 22, 22);
	border-radius: 10px;
	font-size: 2rem;
	position: absolute;
	bottom: 125px;
	transition: 0.5s ease-in-out;
	text-decoration: none;
	color: white;
}
.contact:hover {
	background-color: black;
	border: 2px solid black;
}

@media (max-width: 420px) {
	.wrapper {
		width: 280px;
	}
}

.image {
	width: 100%;
}

.textSection {
	min-height: 120px;
}
