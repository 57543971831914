.wrapper {
	max-width: 1600px;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: auto;
	gap: 0 64px;
	padding: 0 64px;

	@media (min-width: 1000px) {
		gap: 0 128px;
	}
}
