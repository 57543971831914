.container {
	max-width: 200px;
	margin: 0 25px;

	& .title {
		font-size: 1.5rem;
		color: var(--primaryColor);
		padding-top: 25px;
	}

	& .image {
		width: 100%;
		padding-bottom: 25px;
	}

	@media (max-width: 1000px) and (min-width: 850px) {
		width: 160px;

		& .title {
			font-size: 1.3rem;
		}
	}

	@media (max-width: 850px) and (min-width: 700px) {
		width: 140px;
		margin: 0 15px;

		& .title {
			font-size: 1.2rem;
		}
	}

	@media (max-width: 700px) and (min-width: 600px) {
		width: 120px;
		margin: 0 15px;

		& .title {
			font-size: 1.1rem;
		}
	}

	@media (max-width: 600px) and (min-width: 500px) {
		width: 100px;
		margin: 0 10px;

		& .title {
			font-size: 0.9rem;
		}
	}
	@media (max-width: 500px) and (min-width: 400px) {
		width: 80px;
		margin: 0 10px;

		& .title {
			font-size: 0.8rem;
		}
	}
	@media (max-width: 400px) and (min-width: 300px) {
		width: 70px;
		margin: 0 10px;

		& .title {
			font-size: 0.7rem;
		}
	}
}
